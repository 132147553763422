<template>
  <div id="details" v-title data-title="订单详情" :style="{backgroundImage:`url(${bgImg})`}">
    <div class="content" :style="{backgroundImage:`url(${contentBg})`}">
      <div class="order_content_list">
        <div class="content flex justify-between">
          <div class="flex">
            <div class="icon flex align-center justify-center state-blue">
              <img src="@/assets/img/order-icon2.png" alt="">
            </div>
            <div class="msg">
              <h5 class="van-ellipsis">{{ suprname }}</h5>
              <p>{{ telephone }}</p>
            </div>
          </div>
          <div class="content-right">
            <p v-if="state == 0" class="red_color">待支付</p>
            <p v-if="state == 1" class="red_color">待处理</p>
            <p v-if="state == 2" class="subTheme_color">已完成</p>
            <p v-if="state == -2" class="subTheme_color">已拒绝</p>
            <p class="red_color">￥{{ suprprice }}</p>
          </div>
        </div>
        <div class="action flex align-center justify-between">
        </div>
      </div>
      <div class="order-handle-date">
        <div class="subTheme_color">姓名：<span class="theme_color">{{ username }}</span></div>
        <div class="subTheme_color">订单号：<span class="theme_color">{{ ordecode }}</span></div>
        <div class="subTheme_color">订单提交：<span class="theme_color">{{ ctime }}</span></div>
        <div class="subTheme_color">订单支付：<span class="theme_color">{{ paytime }}</span></div>
        <div class="subTheme_color">订单处理：<span class="theme_color">{{ managetime }}</span></div>
      </div>
      <div class="order-handle-date" style="border-bottom: none;">
        <h5>账号信息</h5>
        <div class="subTheme_color flex align-center" v-if="netaccount">上网账号：<span class="theme_color">{{ netaccount }}</span>
          <van-button plain class="copyBtn" @click="copyBtnTxt(item.netaccount)">复制</van-button>
        </div>
        <div class="subTheme_color" v-if="netpwd">上网密码：<span class="theme_color">{{ netpwd }}</span></div>
        <div class="subTheme_color">学校名称：<span class="theme_color">{{ schname }}</span></div>
        <div class="subTheme_color">运营商：<span class="theme_color">{{ company }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import commonApi from "@/api/common";

export default {
  name: 'PageDetails',
  components: {},
  props: {
    // 接口前缀地址
    url: String,
  },
  data() {
    return {
      bgImg: require("@/assets/img/order-details-bg.png"),
      contentBg: require("@/assets/img/result-bg.png"),
      ordecode: this.$route.query.ordecode || "",
      schname: this.$route.query.schname || "",
      netpwd: this.$route.query.netpwd || "",
      netaccount: this.$route.query.netaccount || "",
      ctime: this.$route.query.ctime || "",
      paytime: this.$route.query.paytime || "",
      managetime: this.$route.query.managetime || "",
      username: this.$route.query.username || "",
      suprname: this.$route.query.suprname || "",
      telephone: this.$route.query.telephone || "",
      suprprice: this.$route.query.suprprice,
      state: this.$route.query.state || "",
      company: this.$route.query.company || "",
    };
  },
  mounted() {
    this.getOrderPayState();
  },
  created() {
    this.removeItem();
  },
  methods: {
    /* 获取运营商名称 */
    getOperatorsLabel(val) {
      this.seloper = commonApi.getOperatorsLabel(val)
    },
    /* 复制按钮文字 */
    copyBtnTxt(val) {
      commonApi.copy(val, '复制成功');
    },
    /* 清除localstorage */
    removeItem() {
      window.localStorage.removeItem('ordecode');
      window.localStorage.removeItem('schname');
      window.localStorage.removeItem('netpwd');
      window.localStorage.removeItem('netaccount');
      window.localStorage.removeItem('ctime');
      window.localStorage.removeItem('paytime');
      window.localStorage.removeItem('managetime');
      window.localStorage.removeItem('username');
      window.localStorage.removeItem('suprname');
      window.localStorage.removeItem('telephone');
      window.localStorage.removeItem('suprprice');
      window.localStorage.removeItem('state');
      window.localStorage.removeItem('company');
    },
    getOrderPayState() {
      //    let url = this.url + 'getOrderState';
      //    this.axios.get(url, {
      //      params: {
      //        ordecode: this.ordecode,
      //      }
      //    })
      //        .then(res => {
      //          if (res.data.code != 0) {
      //            this.$toast.fail('订单正在支付...');
      //          }
      //        });
      localStorage.setItem("ordecode", this.ordecode);
      localStorage.setItem("schname", this.schname);
      localStorage.setItem("netpwd", this.netpwd);
      localStorage.setItem("netaccount", this.netaccount);
      localStorage.setItem("ctime", this.ctime);
      localStorage.setItem("paytime", this.paytime);
      localStorage.setItem("managetime", this.managetime);
      localStorage.setItem("username", this.username);
      localStorage.setItem("suprname", this.suprname);
      localStorage.setItem("telephone", this.telephone);
      localStorage.setItem("suprprice", this.suprprice);
      localStorage.setItem("state", this.state);
      localStorage.setItem("company", this.company);
      this.ordecode = localStorage.getItem("ordecode")
      this.schname = localStorage.getItem("schname")
      this.netpwd = localStorage.getItem("netpwd")
      this.netaccount = localStorage.getItem("netaccount")
      this.ctime = localStorage.getItem("ctime")
      this.paytime = localStorage.getItem("paytime")
      this.managetime = localStorage.getItem("managetime")
      this.username = localStorage.getItem("username")
      this.suprname = localStorage.getItem("suprname")
      this.telephone = localStorage.getItem("telephone")
      this.suprprice = localStorage.getItem("suprprice")
      this.state = localStorage.getItem("state")
      this.company = localStorage.getItem("company")
    }
  }
};
</script>
<style scoped lang="scss">
</style>
<style>
</style>
