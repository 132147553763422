<template>
  <div>
    <Details :url="url"/>
  </div>
</template>

<script>
import Details from './../page/details';
import Api from './api';
export default {
  name: 'DetailsVue',
  components: {
    Details
  },
  data () {
    return {
      url: Api.PREFIX
    };
  },
  mounted () {},
  created () {},
  methods: {}
};
</script>
